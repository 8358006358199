import { useEffect } from "react";
import useStore from "../store/warehouse";
import Head from "next/head";

const LayoutOrganizer = ({ children }: { children: React.ReactNode }) => {
  const { loadData, loading, error, setError, setLoading } = useStore();
  useEffect(() => {
    setLoading(true);
    loadData();
  }, []);
  return (
    <div className="layout">
      <Head>
        <title>Alter Expo Image Uploader</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {error && <div onClick={() => setError("")}>{error}</div>}
      {loading && (
        <div id="loader">
          Loading{" "}
          <div className="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="status">{status}</div>
        </div>
      )}

      <main>{children}</main>
      {/* <Footer /> */}
    </div>
  );
};
export default LayoutOrganizer;
