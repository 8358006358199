import { useState } from "react";
import useStore from "../store/warehouse";
import { ImageType } from "../types/image";
import { OrganizerType } from "../types/organizer";
import { SizeType } from "../types/size";
import { StandhoudersType } from "../types/standhouder";
import styles from "./EditOrganizerModal.module.scss";
const EditStandhouderModal = ({
  hideModal,
  standhouder,
  
}: {
  hideModal: () => void;
  standhouder: StandhoudersType;
}) => {
  const { setLoading, editStandhouder, sizes , isAdmin} = useStore();

  const [standNummer, setStandnummer] = useState<string>(
    standhouder.standNummer
  );
  const [contactpersoon, setContactpersoon] = useState<string>(
    standhouder.contactpersoon
  );
  const [name, setName] = useState<string>(standhouder.name);
  const [email, setEmail] = useState<string>(standhouder.email);
  const [fiche, setFiche] = useState<string>(standhouder.fiche);

  return (
    <div
      className="modal"
      onMouseDown={(e) => {
        e.stopPropagation();
        hideModal();
      }}
    >
      <div className="inner" onMouseDown={(e) => e.stopPropagation()}>
        <div className="mb2">
          <h1>Edit Standhouder</h1>
        </div>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            // TODO Check if standnummer is unique
            setLoading(true);
            editStandhouder({
              standNummer,
              contactpersoon,
              name,
              email,
              _id: standhouder._id!,
              newStandNummer: standhouder.standNummer !== standNummer,
            });
            hideModal();
          }}
          className="w100"
        >
          <div className="form-input">
            <label htmlFor="name">Name</label>
            <input
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              name="name"
              id="name"
            />
          </div>

          <div className="form-input">
            <label htmlFor="email">Email</label>
            <input
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              id="email"
            />
          </div>
          <div className="form-input">
            <label htmlFor="contact">Contact person</label>
            <input
              required
              value={contactpersoon}
              onChange={(e) => setContactpersoon(e.target.value)}
              type="text"
              name="contact"
              id="contact"
            />
          </div>
          <div className="form-input">
            <label htmlFor="contact">Standnummer</label>
            <input
              required
              value={standNummer}
              onChange={(e) => setStandnummer(e.target.value)}
              type="text"
              name="contact"
              id="contact"
            />
          </div>
          {/* TODO: even in commentaar, gaat later pas nodig zijn */}

       {/* {isAdmin &&   <div className="form-input mb2">
            <label htmlFor="contact">Fiche</label>
            <input
              required
              value={fiche}
              onChange={(e) => setFiche(e.target.value)}
              type="text"
              name="fiche"
              id="fiche"
            />
          </div>} */}

          <div className="spaceBetween gap2">
            <button
              type="button"
              className="secondary-button"
              onClick={() => hideModal()}
            >
              Cancel
            </button>

            <button className="primary-button">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditStandhouderModal;
