/* eslint-disable @next/next/no-img-element */
import { NextApiRequest, NextApiResponse } from "next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import AddImageModal from "../../components/AddImageModal";
import { database } from "../../constants/database";
import useStore from "../../store/warehouse";
import { ImageType } from "../../types/image";
import { OrganizerType } from "../../types/organizer";
import { SizeType } from "../../types/size";
import { StandhoudersType } from "../../types/standhouder";
import { db } from "../../utils/database";

import {
  FaTrash,
  FaPlus,
  FaInfoCircle,
  FaEnvelope,
  FaQuestionCircle,
  FaBackward,
  FaPen,
  FaPlusCircle,
  FaArrowCircleRight,
  FaArrowLeft,
  FaCaretDown,
  FaCaretUp,
  FaHeart,
  FaDownload,
} from "react-icons/fa";
import { formatMailToStandhouder } from "../../utils/mail";
import EditStandhouderModal from "../../components/EditStandhouderModal";
import LayoutOrganizer from "../../components/LayoutOrganizer";
import StandhouderId from "../api/loadStandhouderData/[standhouderId]";
import { getNiceDate } from "../../utils/date";
import React from "react";

const OrganizationPage = ({}: {}) => {
  const {
    loading,
    organizers,
    standhouders,
    sizes,
    isAdmin,
    images,
    setLoading,
    loadDataOrganizer,
    setSentMail,
    deleteImage,
    setStandhouders,
  } = useStore();
  const router = useRouter();
  const [reminders, setReminders] = useState<StandhoudersType[]>([]);
  const [editTemplate, setEditTemplate] = useState(false);
  const [deleteStandhouder, setDeleteStandhouder] =
    useState<StandhoudersType | null>();
  const [template, setTemplate] = useState("");
  const [showHelp, setShowHelp] = useState(false);
  const [search, setSearch] = useState("");
  const [showDetails, setShowDetails] = useState<
    StandhoudersType | undefined
  >();

  const [sortColumn, setSortColumn] = useState("");
  const [sortDesc, setSortDesc] = useState(1);

  const clickColumn = (col: string) => {
    if (col === sortColumn) {
      setSortDesc(sortDesc * -1);
    } else {
      setSortColumn(col);
      setSortDesc(1);
    }
  };
  const [editStandhouder, setEditStandhouder] =
    useState<StandhoudersType | null>();
  const organizerId = router.query.organizerId as string;
  // const [standHouders, setStandHouders] =
  //   useState<StandhoudersType[]>(standhouders);

  useEffect(() => {
    if (organizerId) {
      setLoading(true);
      loadDataOrganizer(organizerId);
    }
  }, [organizerId]);

  useEffect(() => {
    if (organizerId && organizers.length) {
      const myData = organizers.find((org) => org.link === organizerId);
      setTemplate(
        myData?.emailBody ||
          `Hallo [NAME],

hier vind je de link naar jouw pagina: [LINK]

Groeten,
[ORGANIZER]`
      );
    }
  }, [organizerId, organizers]);

  // useEffect(() => {
  //   if (standhouders.length) {
  //     setStandHouders(
  //       standhouders.filter((s) => s.organizerId === organizerId)
  //     );
  //   }
  // }, [standhouders]);

  const myData = organizers.find((org) => org.link === organizerId);
  const [selectedStandhouder, setSelectedStandhouder] =
    useState<StandhoudersType>();

  const downloadCsv = () => {
    const myImages = images.filter((img) => img.organizerId === myData!.link);
    console.log(myImages, standhouders);
    const csv =
      "event,exhibitor,stand number,name,uploaded,imageName\n" +
      myImages
        .filter((myImg) => {
          const standhouderNumber = standhouders
            .find((sh) => sh.link === myImg.standhouderId)
            ?.standNummer.toString()
            .split(",")
            .join(" ");
          return !!standhouderNumber;
        })
        .map((img) => {
          const standhouderNumber = standhouders
            .find((sh) => sh.link === img.standhouderId)
            ?.standNummer.toString()
            .split(",")
            .join(" ");

          const standhouderName = standhouders
            .find((sh) => sh.link === img.standhouderId)
            ?.name.split(",")
            .join(" ");
          if (standhouderNumber) {
            const imageName =
              img.fileNameWithTimeStamp ||
              `${standhouderNumber}_${standhouderName}_XXXX-XX-XX-XX-XX-XX_${
                img.name
              }_${img.height}_${img.width}_${img.fileName.split(".").pop()}`;

            return `${myData!.nameBeurs},${standhouders
              .find((sh) => sh.link === img.standhouderId)
              ?.name.split(",")
              .join(" ")},${standhouderNumber},${
              img.name
            },${!!img.preview},${imageName.split(",").join(".")}`;
          }
        })
        .join("\n");

    const csvFile = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(csvFile);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${myData!.nameBeurs}.csv`;
    a.click();
    setLoading(false);
  };

  console.log("MYDATA", myData);
  if (!myData) return null;
  return (
    <LayoutOrganizer>
      <div className="page noprint">
        <div className="container">
          {isAdmin && (
            <Link href="/">
              <a className="adminBackButton">
                <FaArrowLeft></FaArrowLeft>Home
              </a>
            </Link>
          )}
          <header className="spaceBetween mb1">
            <h1>
              {myData.logo ? (
                <img
                  className="logo"
                  height={50}
                  src={myData.logo}
                  alt={myData.nameBeurs}
                />
              ) : (
                myData.nameBeurs
              )}
            </h1>
            <div>
              <button className="helpButton" onClick={() => setShowHelp(true)}>
                <FaQuestionCircle></FaQuestionCircle>
              </button>
              <button
                className="link secondary-button mr1"
                onClick={() => {
                  downloadCsv();
                }}
              >
                Download csv <FaDownload className="ml1"></FaDownload>
              </button>
              <button
                onClick={() => setEditTemplate(true)}
                className="link secondary-button mr1"
              >
                edit mail template
              </button>
              <Link href={"/" + organizerId + "/bulk"}>
                <a className="link secondary-button mr1">Add Bulk</a>
              </Link>
              <Link href={"/" + organizerId + "/add"}>
                <a className="link primary-button">Add</a>
              </Link>
            </div>
          </header>

          <div className="mb1">
            <input
              type="search"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>
          <div className="date">{getNiceDate(myData.date)}</div>
          <p className="reminder">
            {
              images.filter(
                (i) =>
                  i.organizerId === organizerId &&
                  standhouders.find((sh) => sh.link === i.standhouderId)
                    ?.active &&
                  i.preview
              ).length
            }
            /
            {
              images.filter(
                (i) =>
                  i.organizerId === organizerId &&
                  standhouders.find((sh) => sh.link === i.standhouderId)?.active
              ).length
            }{" "}
            images have been uploaded.
            <br /> Click{" "}
            <button
              onClick={async () => {
                const arrayOfStandhouderIds = images
                  .filter(
                    (i) =>
                      i.organizerId === organizerId &&
                      standhouders.find((sh) => sh.link === i.standhouderId)
                        ?.active &&
                      !i.preview
                  )
                  .map((i) => i.standhouderId)
                  .filter(function (item, pos, self) {
                    return self.indexOf(item) == pos;
                  });
                const arrayOfStandhouders = standhouders.filter((sh) =>
                  arrayOfStandhouderIds.includes(sh.link)
                );
                setReminders(arrayOfStandhouders);
              }}
              id="sendReminder"
            >
              here
            </button>{" "}
            to send a reminder email.
          </p>
          {/* 
<div>saved filters
  <div className="filters">

  </div>
  <button>
    <FaHeart></FaHeart>
  </button>
</div> */}
          <table className="table">
            <thead>
              <tr>
                <th onClick={() => clickColumn("standNummer")}>
                  Standnummer{" "}
                  {sortColumn === "standNummer" && sortDesc == -1 && (
                    <FaCaretDown></FaCaretDown>
                  )}
                  {sortColumn === "standNummer" && sortDesc === 1 && (
                    <FaCaretUp></FaCaretUp>
                  )}
                </th>
                <th onClick={() => clickColumn("name")}>
                  Name{" "}
                  {sortColumn === "name" && sortDesc == -1 && (
                    <FaCaretDown></FaCaretDown>
                  )}
                  {sortColumn === "name" && sortDesc === 1 && (
                    <FaCaretUp></FaCaretUp>
                  )}
                </th>
                <th onClick={() => clickColumn("contactpersoon")}>
                  Contactpersoon{" "}
                  {sortColumn === "contactpersoon" && sortDesc == -1 && (
                    <FaCaretDown></FaCaretDown>
                  )}
                  {sortColumn === "contactpersoon" && sortDesc === 1 && (
                    <FaCaretUp></FaCaretUp>
                  )}
                </th>
                <th onClick={() => clickColumn("email")}>
                  Email{" "}
                  {sortColumn === "email" && sortDesc == -1 && (
                    <FaCaretDown></FaCaretDown>
                  )}
                  {sortColumn === "email" && sortDesc === 1 && (
                    <FaCaretUp></FaCaretUp>
                  )}
                </th>
                <th>Images</th>
                <th>Last uploaded</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {standhouders
                .filter((s) => s.organizerId === organizerId)
                .filter(
                  (sh) =>
                    sh.name?.toLowerCase().includes(search?.toLowerCase()) ||
                    sh.email?.toLowerCase().includes(search?.toLowerCase()) ||
                    sh.contactpersoon
                      ?.toLowerCase()
                      .includes(search?.toLowerCase())
                )
                .sort((a, b) =>
                  //@ts-ignore
                  a[sortColumn] < b[sortColumn] ? -1 * sortDesc : 1 * sortDesc
                )
                .map((standhouder, index) => {
                  return (
                    <tr key={standhouder._id}>
                      <td>{standhouder.standNummer}</td>
                      <td>
                        <Link href={"/images/" + standhouder.link}>
                          <a>{standhouder.name}</a>
                        </Link>
                      </td>
                      <td>{standhouder.contactpersoon}</td>
                      <td>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={formatMailToStandhouder(
                            myData,
                            standhouder,
                            template
                          )}
                          className="sendMail"
                          onClick={() => {
                            setSentMail(standhouder.link, true);
                          }}
                          id={"sendEmail" + index}
                        >
                          {standhouder.email}
                          <FaEnvelope
                            style={{
                              color: standhouder.emailSent
                                ? "green"
                                : "var(--light-grey)",
                            }}
                          ></FaEnvelope>
                        </a>
                      </td>
                      <td>
                        {
                          images.filter(
                            (img) =>
                              img.standhouderId === standhouder.link &&
                              img.preview
                          ).length
                        }{" "}
                        /{" "}
                        {
                          images.filter(
                            (img) => img.standhouderId === standhouder.link
                          ).length
                        }
                        <button
                          onClick={() => setShowDetails(standhouder)}
                          className="tableActionButton"
                          id={"infoButton" + index}
                        >
                          <FaInfoCircle></FaInfoCircle>
                        </button>
                      </td>
                      <td>
                        {getNiceDate(
                          images
                            .filter(
                              (img) =>
                                img.standhouderId === standhouder.link &&
                                img.preview
                            )
                            .sort((a, b) => {
                              if (a.dateUploaded > b.dateUploaded) {
                                return -1;
                              }
                              if (a.dateUploaded < b.dateUploaded) {
                                return 1;
                              }
                              return 0;
                            })[0]?.dateUploaded,
                          true
                        )}
                      </td>
                      <td>
                        <button
                          onClick={() => setSelectedStandhouder(standhouder)}
                          title="Add image"
                          id={"addImage" + index}
                        >
                          <FaPlusCircle></FaPlusCircle>
                        </button>

                        <Link href={"/images/" + standhouder.link}>
                          <a id={"goToStandhouder" + index}>
                            <FaArrowCircleRight></FaArrowCircleRight>
                          </a>
                        </Link>
                        <button
                          className="delete"
                          onClick={() => {
                            setEditStandhouder(standhouder);
                          }}
                          id={"editStandhouder" + index}
                        >
                          <FaPen />
                        </button>
                        <button
                          className="delete"
                          onClick={() => {
                            setDeleteStandhouder(standhouder);
                          }}
                          id={"deleteStandhouder" + index}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <AddImageModal
            sizes={sizes}
            organizer={myData}
            organizerId={organizerId}
            standhouder={selectedStandhouder}
            setSelectedStandhouder={setSelectedStandhouder}
          ></AddImageModal>
          {editStandhouder && (
            <EditStandhouderModal
              standhouder={editStandhouder}
              hideModal={() => setEditStandhouder(null)}
            />
          )}
          {deleteStandhouder && (
            <div
              className="modal"
              onMouseDown={() => setDeleteStandhouder(null)}
            >
              <div className="inner" onMouseDown={(e) => e.stopPropagation()}>
                <div className="mb2">
                  <h2>Delete {deleteStandhouder.name}</h2>
                  <p>
                    Are you sure you want to delete {deleteStandhouder.name}?
                  </p>
                </div>

                <div className="spaceBetween gap2">
                  <button
                    type="button"
                    className="secondary-button"
                    onClick={() => setDeleteStandhouder(null)}
                  >
                    cancel
                  </button>

                  <button
                    onClick={() => {
                      setStandhouders(
                        standhouders.filter(
                          (s) => s.link !== deleteStandhouder.link
                        )
                      );
                      // this sets them in the store, above only on the page
                      // setStandhouders(
                      //   standHouders.filter(
                      //     (s) => s.link !== deleteStandhouder.link
                      //   )
                      // );
                      setDeleteStandhouder(null);
                      fetch(`/api/standhouders`, {
                        method: "DELETE",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          standhouderId: deleteStandhouder.link,
                        }),
                      });
                    }}
                    className="primary-button"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
          {editTemplate && (
            <div className="modal" onMouseDown={() => setEditTemplate(false)}>
              <div className="inner" onMouseDown={(e) => e.stopPropagation()}>
                <div className="mb2">
                  <h2>Mail template</h2>

                  <textarea
                    name="template"
                    id="template"
                    cols={30}
                    rows={10}
                    value={template}
                    onChange={(e) => setTemplate(e.target.value)}
                  ></textarea>
                </div>

                <div className="spaceBetween gap2">
                  <button
                    type="button"
                    className="secondary-button"
                    onClick={() => setEditTemplate(false)}
                  >
                    cancel
                  </button>

                  <button
                    onClick={() => {
                      fetch(`/api/publicOrganizers`, {
                        method: "PUT",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          _id: organizerId,
                          template,
                        }),
                      });
                      setEditTemplate(false);
                    }}
                    className="primary-button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
          {showDetails && (
            <div
              className="modal"
              onMouseDown={() => setShowDetails(undefined)}
            >
              <div className="inner" onMouseDown={(e) => e.stopPropagation()}>
                <h2 className="mb1">
                  {showDetails.standNummer} - {showDetails.name}
                </h2>
                <ul className="imageList mb1">
                  {images
                    .filter((img) => img.standhouderId === showDetails.link)
                    .map((img, index) => {
                      console.log(img.dateUploaded);
                      return (
                        <li key={index}>
                          {img.preview ? (
                            <img
                              height={30}
                              alt={"preview voor: " + img.fileName}
                              src={img.preview}
                            />
                          ) : (
                            <span className="emptyImage">?</span>
                          )}
                          {img.name}

                          <button
                            className="tableActionButton"
                            onClick={() => {
                              setLoading(true);
                              deleteImage(img);
                            }}
                          >
                            <FaTrash></FaTrash>
                          </button>
                        </li>
                      );
                    })}
                </ul>
                <div className="spaceBetween gap2">
                  <button
                    type="button"
                    className="secondary-button"
                    onClick={() => setShowDetails(undefined)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
          {showHelp && (
            <div className="modal" onClick={() => setShowHelp(false)}>
              <img
                className="helpImage"
                src="/howto.png"
                alt="image with information what all buttons do"
              />
            </div>
          )}

          {loading && (
            <div id="loader">
              Loading{" "}
              <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}

          {!!reminders.length && (
            <div className="modal" onMouseDown={() => setReminders([])}>
              <div className="inner" onMouseDown={(e) => e.stopPropagation()}>
                <h2 className="mb1">Send reminder mail</h2>

                <p>Following exhibitors have not yet uploaded their images:</p>
                <ul className="listButtons mb1">
                  {reminders.map((reminder, index) => {
                    return (
                      <li key={index}>
                        {reminder.standNummer} - {reminder.name}
                      </li>
                    );
                  })}
                </ul>
                <div className="spaceBetween gap2">
                  <button
                    type="button"
                    className="secondary-button"
                    onClick={() => setReminders([])}
                  >
                    Close
                  </button>

                  <button
                    onClick={(e) => {
                      //@ts-ignore
                      e.target.disabled = true;
                      const response = fetch("/api/mail", {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                          organizer: myData,
                          date: getNiceDate(myData.date),
                          standhouders: reminders,
                        }),
                      });
                      setReminders([]);
                    }}
                    className="primary-button"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="page print">
        <div className="container">
          <h1>
            {myData.logo ? (
              <img
                className="logo"
                height={50}
                src={myData.logo}
                alt={myData.nameBeurs}
              />
            ) : (
              myData.nameBeurs
            )}
          </h1>

          <table className="table printtable">
            <thead>
              <tr>
                <th>Standnummer</th>
                <th>Name</th>
                <th>Contactpersoon</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {standhouders.map((standhouder) => {
                return (
                  <React.Fragment key={standhouder._id}>
                    <tr>
                      <td>{standhouder.standNummer}</td>
                      <td>
                        <Link href={"/images/" + standhouder.link}>
                          <a>{standhouder.name}</a>
                        </Link>
                      </td>
                      <td>{standhouder.contactpersoon}</td>

                      <td>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={formatMailToStandhouder(
                            myData,
                            standhouder,
                            template
                          )}
                          className="sendMail"
                          onClick={() => {
                            setSentMail(standhouder.link, true);
                          }}
                        >
                          {standhouder.email}
                          <FaEnvelope
                            style={{
                              color: standhouder.emailSent
                                ? "green"
                                : "var(--light-grey)",
                            }}
                          ></FaEnvelope>
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {images
                          .filter(
                            (img) => img.standhouderId === standhouder.link
                          )
                          .map((img, index) => {
                            return (
                              <div key={index}>
                                {img.name}
                                {img.preview && (
                                  <img
                                    alt={"preview voor: " + img.fileName}
                                    src={img.preview}
                                  />
                                )}
                              </div>
                            );
                          })}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </LayoutOrganizer>
  );
};

export default OrganizationPage;
