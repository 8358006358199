export const getNiceDate = (date: string, showHour?: boolean): string => {
  if (!date) return "/";
  if (showHour) {
    return (
      new Date(date)?.toLocaleDateString() +
      " " +
      new Date(date)?.toLocaleTimeString()
    );
  }
  return date ? new Date(date)?.toLocaleDateString() : "/";
};

export const getStringTimeStamp = (): string => {
  // format as YYYY-MM-DD HH:mm:ss
  return new Date()
    .toISOString()
    .slice(0, 19)
    .replace("T", " ")
    .split(" ")
    .join("-")
    .split(":")
    .join("-");
};
